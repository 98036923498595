(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/campaign-step/assets/javascripts/campaign-step.js') >= 0) return;  svs.modules.push('/components/lb-ui/campaign-step/assets/javascripts/campaign-step.js');
"use strict";


const {
  StepIndicator,
  Step
} = svs.ui.ReactStepIndicator;
const defaultSteps = ['Bli kund', 'Sätt in', 'Spela', 'Få bonus'];
const CampaignStep = _ref => {
  let {
    steps = defaultSteps,
    currentStepIndex,
    branding
  } = _ref;
  return React.createElement("div", {
    className: "campaign-step"
  }, React.createElement(StepIndicator, {
    block: false,
    branding: branding,
    isClosed: true
  }, steps.map((step, i) => React.createElement(Step, {
    isSelected: currentStepIndex >= i,
    key: step
  }, step))));
};
setGlobal('svs.components.lbUi.campaignStep.CampaignStep', CampaignStep);

 })(window);